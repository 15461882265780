import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { contentfulClient } from 'Services';



const query = (isTesting) => {
  const date = new Date()
  return `
  query {  
    bannersCollection(where: {
      agencyCode:"TN"
      isMainBanner: true
      startDateTime_lt: "${date.toISOString()}"
      endDateTime_gte: "${date.toISOString()}"
      environment:"${isTesting ? 'testing' : 'production'}"
    }) {
      items {
           xsmall {
             url
           },
           small {
            url
          },
          medium{
            url
          },
          large{
            url
          },
          xlarge{
            url
          },
          xxlarge{
            url
          },
        startDateTime
        agencyCode
        name
        url
        environment
        order,
        isMainBanner
      }
    }
  }`
}

const StyledImg = styled.img`
  border-radius: 14px;
  cursor: pointer;
`;
const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;


export const useBreakpoints = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const breakpoints = {
    xsmall: windowSize < 540,
    small: windowSize >= 540 && windowSize < 768,
    medium: windowSize >= 768 && windowSize < 960,
    large: windowSize >= 960 && windowSize < 1140,
    xlarge: windowSize >= 1140 && windowSize < 1366,
    xxlarge: windowSize >= 1366
  };

  return breakpoints;

};

export const ItauBanner = ({ noMargin = false, loadBannerHome }: { noMargin: boolean, loadBannerHome?: boolean }) => {

  const breakpoints = useBreakpoints();

  const [bannerData, setBannerData] = useState(null);
  const isTesting = (window.location.host.indexOf('testing') > -1) 

  useEffect(() => {
    const getBannerData = async () => {
      const response = await contentfulClient.post('', { query: query(isTesting) });
      const data = response.data.data.bannersCollection.items;
      const orderedData = data.sort((a, b) => a.order - b.order);
      setBannerData(orderedData);
    };

    getBannerData();
  }, [isTesting, setBannerData]);

  const getImageSource = (item) => {
    let imageSrc: string;
    if (breakpoints.xxlarge) {
      imageSrc = item.xxlarge.url;
    } else if (breakpoints.xlarge) {
      imageSrc = item.xlarge.url;
    } else if (breakpoints.large) {
      imageSrc = item.large.url;
    } else if (breakpoints.medium) {
      imageSrc = item.medium.url;
    } else if (breakpoints.small) {
      imageSrc = item.small.url;
    } else {
      imageSrc = item.xsmall.url;
    }

    return imageSrc
  }

  const handleClick = (url) => () => window.open(url, "_blank");

  return (
    bannerData &&
    bannerData.map((item) => (
      <BannerContainer key={item.order} onClick={handleClick(item.url)}>
        <StyledImg src={getImageSource(item)} alt={item.name} />
      </BannerContainer>
    ))
  );
}

